import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAudibleAlert } from 'hooks/useAudibleAlram/useAudibleAlert';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedDrillerView, getView, getViewDrillerAlert, getViews } from 'store/actions/settings';
import Cookies from 'js-cookie';
import _ from 'lodash';
import { getTrackTanks, getTrackTraces, updateAlertMessage } from 'store/actions/liveData';
import { getAllTraces } from 'store/actions/general';
import { httpService } from 'api/api';
import { Modal } from 'antd';
import ApiErrorHandler from 'utils/ApiErrorHandler';

const socketSettings = new WebSocket(process.env.REACT_APP_API_SOCKET_RELOAD);

function withAudibleAlert(WrappedComponent) {
  return function WithAudibleAlert(props) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.general);
    const { view, selectedDrillerView, views, drillerViews } = useSelector((state) => state.settings);
    const allTraces = useSelector((state) => state.general.traces);
    const { tracesTanks, traces, alertUpdateMessage } = useSelector((state) => state.liveData);
    const settingsData = useSelector((state) => state.settings.settings);
    const viewId = useSelector((state) => state.settings.view.id);
    const [activeAlerts, setActiveAlerts] = useState([]);
    const [modalVisibility, setModalVisibility] = useState({});
    const selectedViewName = user?.usersetting?.selected_view;
    const SelectedViewDrillerName = user?.usersetting?.selected_view_driller;
    const findDrillerView = _.find(drillerViews, { name: user?.usersetting?.selected_view_driller });
    const drillerViewID = selectedDrillerView?.id || findDrillerView?.id;
    
    // const getDrillerViewwithID = () => {
    //   if(drillerViewID) {
    //     console.log("called");
    //     dispatch(getSelectedDrillerView(drillerViewID))
    //   } 
    // };

    const sendMyMessages = (eventMessage) => {
      socketSettings.send(JSON.stringify({ event: eventMessage }));
    };

    useEffect(() => {
      dispatch(getTrackTraces());
      dispatch(getAllTraces());
      // dispatch(getTrackDriller());
      dispatch(getTrackTanks());
      dispatch(getViews());
      // dispatch(getDrillerView());
      // dispatch(getGaugesDriller());
      // dispatch(getDrillingTanks());
    }, []);

    useEffect(() => {
      if (alertUpdateMessage === 'UPDATE_ALERT_drillerTracks') {
        // dispatch(getTrackDriller());
        dispatch(getSelectedDrillerView(drillerViewID));
        // dispatch(getDrillerView())
        dispatch(updateAlertMessage(''));
      } else if (alertUpdateMessage === 'UPDATE_ALERT_tankTracks') {
        dispatch(getTrackTanks());
        dispatch(getSelectedDrillerView(drillerViewID));
        dispatch(updateAlertMessage(''));
      } else if (alertUpdateMessage === 'UPDATE_ALERT_mainTraces') {
        dispatch(getView(viewId));
        dispatch(getSelectedDrillerView(drillerViewID));
        dispatch(updateAlertMessage(''));
      } else if (alertUpdateMessage === 'UPDATE_ALERT_mainTrackTraces') {
        dispatch(getTrackTraces());
        dispatch(getSelectedDrillerView(drillerViewID));
        dispatch(updateAlertMessage(''));
      } else if (alertUpdateMessage === 'UPDATE_ALERT_drillerGauges') {
        // dispatch(getGaugesDriller());
        dispatch(getSelectedDrillerView(drillerViewID));
        // dispatch(getDrillerView())
        dispatch(updateAlertMessage(''));
      } else if (alertUpdateMessage === 'UPDATE_ALERT_tankDrillerTracks') {
        // dispatch(getDrillingTanks());
        dispatch(getSelectedDrillerView(drillerViewID));
        // dispatch(getDrillerView())
        dispatch(updateAlertMessage(''));
      }
    }, [alertUpdateMessage]);

    useEffect(() => {
      const findViewId = _.find(views, { name: selectedViewName })?.id;
      const findDrillerId = _.find(drillerViews, { name: SelectedViewDrillerName })?.id;

      if (findViewId) {
        dispatch(getView(findViewId));
      }
      if (findDrillerId) {
        dispatch(getViewDrillerAlert(findDrillerId));
      }
    }, [drillerViews, user, views]);

    useEffect(() => {
      const visibility = {};
      activeAlerts.forEach((alert) => {
        visibility[alert.id] = true;
      });
      setModalVisibility(visibility);
    }, [activeAlerts]);

    const tracesData = useMemo(
      () => ({
        listOfAllTraces:
          view?.chart_set?.flatMap((item) =>
            item?.trace_set?.map((trace) => ({
              ...trace,
              type: 'mainTraces',
            }))
          ) ?? [],
        listOfAllTracesDriller:
          selectedDrillerView?.drillerchart_set?.flatMap((item) =>
            item?.drillertrace_set?.map((trace) => ({
              ...trace,
              type: 'drillerTraces',
            }))
          ) ?? [],
        listOfAllTracesTrack:
          traces?.map((item) => {
            const traceParameter = _.find(allTraces, { input: item?.name })?.parameter;
            return { ...item, parameter: traceParameter, type: 'mainTrackTraces' };
          }) ?? [],
        enhancedTracesDriller: selectedDrillerView?.drillingtrackes_set?.map((trace) => ({
          ...trace,
          type: 'drillerTracks',
        })),
        // ? enhancedTracesDriller: tracesDriller.map((trace) => ({ ...trace, type: 'drillerTracks' })),
        enhancedTracesTanks: tracesTanks.map((trace) => ({ ...trace, type: 'tankTracks' })),
        // ! enhancedTracesDrillerTanks: tracesDrillingTanks.map((trace) => ({ ...trace, type: 'tankDrillerTracks' })),
        enhancedTracesDrillerTanks: selectedDrillerView?.tankconfig_set?.map((trace) => ({
          ...trace,
          type: 'tankDrillerTracks',
        })),
        enhancedDrillerGauges: selectedDrillerView?.drillinggauge_set?.map((trace) => ({
          ...trace,
          type: 'drillerGauges',
        })),
        // ? enhancedDrillerGauges: gaugesDriller.map((trace) => ({ ...trace, type: 'drillerGauges' })),
      }),
      [
        view,
        traces,
        allTraces,
        // tracesDriller,
        tracesTanks,
        // gaugesDriller,
        selectedDrillerView,
        // tracesDrillingTanks,
      ]
    );

    const listOfAudibleAlarms = useMemo(() => {
      const list = _.concat(
        tracesData.listOfAllTraces,
        tracesData.listOfAllTracesDriller,
        tracesData.listOfAllTracesTrack,
        tracesData.enhancedTracesDriller,
        tracesData.enhancedTracesTanks,
        tracesData.enhancedTracesDrillerTanks,
        tracesData.enhancedDrillerGauges
      );

      return _.filter(list, { alert: true });
    }, [tracesData]);

    useEffect(() => {
      setActiveAlerts(listOfAudibleAlarms.filter((alarm) => alarm.alert && !alarm.snooze));
    }, [listOfAudibleAlarms]);

    const { shouldAlert, shouldAlertId } = useAudibleAlert(listOfAudibleAlarms || []);

    useEffect(() => {
      // Update active alerts when listOfAudibleAlarms changes
      const active = listOfAudibleAlarms.filter((alarm) => alarm.alert && !alarm.snooze);
      setActiveAlerts(active);
      // setShouldShowModal(true);
    }, [listOfAudibleAlarms]);

    // ! TEMP CODE
    const menuRef = useRef(null);

    const countdownRef = useRef(null);
    const [timers, setTimers] = useState({});

    const toggleSnoozeTrackTrace = (traceId, snooze, type) => {
      console.log('traceId, snooze, type',
        traceId, snooze, type
      );
      const response = () => {
        if (type === 'drillerTracks') {
          // dispatch(getTrackDriller());
          dispatch(getSelectedDrillerView(drillerViewID));
          // dispatch(getDrillerView())
        } else if (type === 'tankTracks') {
          dispatch(getTrackTanks());
        } else if (type === 'mainTraces') {
          dispatch(getSelectedDrillerView(drillerViewID));
          dispatch(getView(viewId));
          // dispatch(getDrillerView())
          // dispatch(getDrillerView())
        }
        else if (type === 'drillerTraces') {
          dispatch(getSelectedDrillerView(drillerViewID));
          dispatch(getView(viewId));
          // dispatch(getDrillerView())
          // dispatch(getDrillerView())
        } else if (type === 'mainTrackTraces') {
          dispatch(getTrackTraces());
        } else if (type === 'drillerGauges') {
          // dispatch(getGaugesDriller());
          dispatch(getSelectedDrillerView(drillerViewID));
          // dispatch(getDrillerView())
          // dispatch(getDrillerView())
          dispatch(getSelectedDrillerView(drillerViewID));
          // dispatch(getDrillerView())
        } else if (type === 'tankDrillerTracks') {
          // dispatch(getDrillingTanks());
          // dispatch(getDrillerView())
          dispatch(getSelectedDrillerView(drillerViewID));
          // dispatch(getDrillerView())
        }
      };

      const error = (err) => {
        ApiErrorHandler(err);
      };

      sendMyMessages(`UPDATE_ALERT_${type}`);

      if (type === 'drillerTracks') {
        httpService.updateTrackDriller(response, error, traceId, { snooze });
      } else if (type === 'tankTracks') {
        httpService.updateTrackTanks(response, error, traceId, { snooze });
      } else if (type === 'mainTraces') {
        // if (window.location.pathname.includes('driller')) {
        //   return httpService.updateDrillerViewTrace(response, error, traceId, { snooze });
        // }
        httpService.toggleSnoozeTrace(response, error, traceId, { snooze });
      } else if (type === 'mainTrackTraces') {
        httpService.toggleSnoozeTrackTrace(response, error, traceId, { snooze });
      } else if (type === 'drillerGauges') {
        httpService.updateGaugesDriller(response, error, traceId, { snooze });
      } else if (type === 'tankDrillerTracks') {
        httpService.updateDrillingTanks(response, error, traceId, { snooze });
      } else if (type === 'drillerTraces') {
        httpService.updateDrillerViewTrace(response, error, traceId, { snooze });
      }
    };

    // Function to initialize timers
    const initializeTimers = (alarms) => {
      const initialTimers = {};
      alarms.forEach((alarm) => {
        const cookieKey = `${alarm.parameter?.replace(/\s+/g, '')}+${alarm.id}`;
        const cookieValue = Cookies.get(cookieKey);
        initialTimers[alarm.id] = cookieValue ? parseInt(cookieValue, 10) : Number(settingsData.snooze_time) * 60 || 60;
      });
      setTimers(initialTimers);
    };

    useEffect(() => {
      initializeTimers(listOfAudibleAlarms);

      // Function to clear the interval
      const clearIntervalTimer = () => {
        if (countdownRef.current) {
          clearInterval(countdownRef.current);
          countdownRef.current = null;
        }
      };

      // Start the countdown timer
      countdownRef.current = setInterval(() => {
        setTimers((prevTimers) => {
          const newTimers = { ...prevTimers };
          listOfAudibleAlarms.forEach((alarm) => {
            if (alarm.snooze && alarm.alert && alarm.parameter) {
              const cookieKey = `${alarm.parameter?.replace(/\s+/g, '')}+${alarm.id}`;
              if (newTimers[alarm.id] <= 1) {
                toggleSnoozeTrackTrace(alarm.id, false, alarm.type);
                Cookies.remove(cookieKey, newTimers[alarm.id]);
                // setShouldShowModal(true);
                newTimers[alarm.id] = Number(settingsData.snooze_time) * 60 || 60;
              } else {
                // eslint-disable-next-line no-plusplus
                newTimers[alarm.id]--;
              }
              Cookies.set(cookieKey, newTimers[alarm.id]);
            }
          });
          return newTimers;
        });
      }, 1000);

      return () => clearIntervalTimer();
    }, [listOfAudibleAlarms]);

    // const AlarmModalFooter = ({ id, type }) => {
    //   const [deactivate, setDeactivate] = useState(false);

    //   const onSnooze = () => {
    //     toggleSnoozeTrackTrace(id, true, type);
    //     setDeactivate(true);
    //   };

    //   const onLeave = (alertId) => {
    //     setModalVisibility((prev) => ({ ...prev, [alertId]: false }));
    //     setDeactivate(true);
    //   };

    //   return (
    //     <div className="w-full flex justify-end items-center space-x-2">
    //       <Button
    //         disabled={deactivate}
    //         onClick={onSnooze}
    //         className={deactivate ? 'bg-lightDark text-white' : 'bg-error border-error text-white'}
    //       >
    //         Snooze Alarm {settingsData?.snooze_time ? Number(settingsData?.snooze_time) : 'one'} minute
    //       </Button>
    //       <Button
    //         disabled={deactivate}
    //         onClick={() => onLeave(id)}
    //         className={
    //           deactivate
    //             ? 'bg-lightDark text-white'
    //             : 'text-primaryDark border-primaryDark hover:text-primaryDark dark:border-white dark:text-white dark:hover:text-white'
    //         }
    //       >
    //         Leave Alarm on
    //       </Button>
    //     </div>
    //   );
    // };

    const onSnooze = (id, type) => {
      toggleSnoozeTrackTrace(id, true, type);
    };

    const onLeave = (alertId) => {
      setModalVisibility((prev) => ({ ...prev, [alertId]: false }));
    };

    return (
      <div>
        {/* Render the original component */}
        <WrappedComponent shouldAlert={shouldAlert} {...props} />
        {activeAlerts.map((alert) => (
          <Modal
            styles={{
              mask: {
                background: 'transparent',
              },
            }}
            key={alert.id} // Use a unique key for each alert, like its ID
            title={
              <div className=" flex items-center w-full gap-x-2">
                <h2 className="text-xl font-bold">Audible Alert</h2>
                <div className="capitalize text-lg">
                  {alert.type === 'drillerTracks' && <div className="font-semibold">In Driller Menu</div>}
                  {alert.type === 'drillerTraces' && <div className="font-semibold">In Driller Menu</div>}
                  {alert.type === 'tankTracks' && <div className="font-semibold">In Tank Menu</div>}
                  {alert.type === 'mainTraces' && <div className="font-semibold">in Home Page</div>}
                  {alert.type === 'drillerGauges' && <div className="font-semibold">in Driller for gauges</div>}
                  {alert.type === 'tankDrillerTracks' && <div className="font-semibold">in Driller for Tanks</div>}
                  {alert.type === 'mainTrackTraces' && (
                    <div className="font-semibold">in Home Page (right sidebar)</div>
                  )}
                </div>
              </div>
            }
            centered
            open={shouldAlertId?.includes(alert.id) && user?.audible_alert ? modalVisibility[alert.id] : false} // Modal is always open for active alerts
            maskClosable={false}
            closable={false}
            // footer={<AlarmModalFooter id={alert.id} type={alert?.type} />}
            okText={`Snooze Alarm ${settingsData?.snooze_time ? Number(settingsData?.snooze_time) : 'one'} minute`} // Custom text for the OK button
            onOk={() => onSnooze(alert.id, alert.type)}
            onCancel={() => onLeave(alert.id)}
            okButtonProps={{
              style: {
                backgroundColor: '#E84749', // Custom background color
                borderColor: '#E84749', // Custom border color
                color: 'white', // Custom text color
              },
            }}
          >
            <p className="capitalize">
              An audible alarm active for{' '}
              <span className="font-bold text-error">
                {alert?.name || alert?.input || alert?.parameter || 'Unknown Sensor'}
              </span>
              . Snooze it for {settingsData?.snooze_time ? Number(settingsData?.snooze_time) : 'one'} minute?
            </p>
          </Modal>
        ))}
      </div>
    );
  };
}

export default withAudibleAlert;
