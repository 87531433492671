import * as types from '../types';

const initialState = {
  data: {},
  dataLiveAlert: {},
  frequentLiveData: {},
  liveStatus: false,
  traces: [],
  tracesDriller: [],
  tracesTanks: [],
  tracesDrillingTanks: [],
  gaugesDriller: [],
  forceROP: false,
  auto_tally_stand_number: 0,
  auto_tally_stand_status: 0,
  chartDelayUPDATE: 1000,
  updateWellProfileTime: {},
  importConstantsLists: [],
  drillStringLength: 0,
  autoDepthReferenceWeight: 0,
  tallyMode: null,
  alertUpdateMessage: ''
};

export default function liveData(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_LIVE_DATA:
      return {
        ...state,
        data: payload,
      };
    case types.GET_LIVE_DATA_ALERT:
      return {
        ...state,
        dataLiveAlert: payload,
      };
    case types.GET_FREQUENT_LIVE_DATA:
      return {
        ...state,
        frequentLiveData: payload,
      };
    case types.UPDATE_WELL_PROFILE_TIME:
      return {
        ...state,
        updateWellProfileTime: payload,
      };
    case types.SET_STATUS_API:
      return {
        ...state,
        liveStatus: payload,
      };
    case types.GET_TALLY_MODE:
      return {
        ...state,
        tallyMode: payload,
      };
    case types.GET_TALLY_STAND_STATUS:
      return {
        ...state,
        auto_tally_stand_status: payload,
      };
    case types.GET_TALLY_STAND_NUMBER:
      return {
        ...state,
        auto_tally_stand_number: payload,
      };
    case types.UPDATE_ALERT_MESSAGE:
      return {
        ...state,
        alertUpdateMessage: payload,
      };
    case types.GET_AUTO_DEPTH_REFERENCE_WEIGHT:
      return {
        ...state,
        autoDepthReferenceWeight: payload,
      };
    case types.SET_IMPORT_CONSTANTS_LISTS:
      // eslint-disable-next-line no-case-declarations
      const isExisting = state.importConstantsLists.includes(payload);
      return {
        ...state,
        importConstantsLists: isExisting
          ? state.importConstantsLists // If it exists, just return the current state
          : [...state.importConstantsLists, payload], // Otherwise, add the new payload
      };
    case types.GET_FORCE_ROP:
      return {
        ...state,
        forceROP: payload,
      };
    case types.GET_TRACK_TRACES:
      return {
        ...state,
        traces: payload,
      };
    case types.GET_TRACK_TRACES_DRILLER:
      return {
        ...state,
        tracesDriller: payload,
      };
    case types.GET_TRACK_TRACES_TANKS:
      return {
        ...state,
        tracesTanks: payload,
      };
    case types.GET_DRILLING_TRACES_TANKS:
      return {
        ...state,
        tracesDrillingTanks: payload,
      };
    case types.GET_GAUGES_TRACES_DRILLER:
      return {
        ...state,
        gaugesDriller: payload,
      };
    case types.SET_DELAY_CHART:
      return {
        ...state,
        chartDelayUPDATE: payload,
      };
    case types.GET_DRILL_STRING_LENGTH:
      return {
        ...state,
        drillStringLength: payload,
      };
    default:
      return state;
  }
}
