import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BsGraphUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {
  PiMonitorPlayThin,
  PiFilesThin,
  PiSpeedometerLight,
  PiClockClockwiseLight,
  PiPresentationChartLight,
  PiFilePdfLight,
  PiExportLight,
} from 'react-icons/pi';
import { IoSettingsOutline } from 'react-icons/io5';
import useDarkMode from 'hooks/useDarkMode';
import _ from 'lodash';

const DrawerMenu = () => {
  const [isDarkMode] = useDarkMode();
  const [visible, setVisible] = useState(false);
  const { user } = useSelector((state) => state.general);
  const location = useLocation(); // To get the current URL
  const generalView = useSelector((state) => state.settings.views);
  const { drillerViews } = useSelector((state) => state.settings);
  const isUpdatingDrillerMenu = window.location.pathname.includes('driller-menu');
  // Show the drawer when mouse enters, hide it when mouse leaves
  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  // eslint-disable-next-line no-nested-ternary
  const listOfViews = window.location.pathname.includes('create-view')
    ? [...generalView, ...drillerViews]
    : !window.location.pathname.includes('driller-menu')
    ? generalView
    : drillerViews;

  const selectedViewId =
    _.find(listOfViews, { name: isUpdatingDrillerMenu ? user.selected_view_driller : user.selected_view })?.id ||
    _.find(listOfViews, {
      name: isUpdatingDrillerMenu ? user?.usersetting?.selected_view_driller : user?.usersetting?.selected_view,
    })?.id;

  // Menu items
  const items = [
    {
      key: '1',
      icon: <BsGraphUp size={24} />,
      label: <Link to="/home">Home</Link>,
      path: '/home', // URL path to match
    },
    {
      key: '2',
      icon: <PiClockClockwiseLight size={24} />,
      label: <Link to="/historical">Historical</Link>,
      path: '/historical',
    },
    {
      key: '3',
      icon: <PiSpeedometerLight size={24} />,
      label: <Link to="/driller-menu">Driller’s Menu</Link>,
      path: '/driller-menu',
    },
    {
      key: '4',
      icon: <PiPresentationChartLight size={24} />,
      label: <Link to="/tank-menu">Tank Menu</Link>,
      path: '/tank-menu',
    },
    {
      key: '5',
      icon: <PiMonitorPlayThin size={24} />,
      label: <Link to="/monitoring">Monitoring</Link>,
      path: '/monitoring',
    },
    {
      key: '5',
      icon: <PiExportLight size={24} />,
      label: <Link to="/export">export files</Link>,
      path: '/export',
    },
    {
      key: '5',
      icon: <PiFilePdfLight size={24} />,
      label: <Link to={selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page'}>report pdf</Link>,
      path: selectedViewId ? `/report-page?viewId=${selectedViewId}` : '/report-page',
    },
  ];

  // Bottom menu items
  const menubottom = [
    {
      key: '6',
      icon: <PiFilesThin size={24} />,
      label: <Link to="/logs">Logs</Link>,
      path: '/logs',
    },
    {
      key: '7',
      icon: <IoSettingsOutline size={24} />,
      label: <Link to="/settings">Settings</Link>,
      path: '/settings',
    },
  ];

  return (
    <div
      className="sidebar-menu-container h-full w-[50px] max-w-[50px] rounded-md dark:text-white dark:border-lightDark"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`sidebar ${
          visible ? 'w-[240px]' : 'w-[50px]'
        } transition-all duration-300 rounded-md border border-gray-200 ${isDarkMode ? 'bg-dark' : 'bg-white'}`}
      >
        <div className="w-full flex flex-col justify-between h-full">
          {/* Top Menu */}
          <ul className="flex flex-col px-1 space-y-4 pt-5">
            {items.map((item) => (
              <li
                key={item.key}
                className={`p-2 cursor-pointer rounded flex items-start justify-start  hover:bg-primary/30 ${
                  location.pathname === item.path ? 'bg-primary/30 dark:bg-primary/30' : ''
                }`}
              >
                <div className={`${visible ? 'pr-2' : 'pr-0'}`}>{item.icon}</div>
                <span className={`${visible ? 'visible w-full' : 'hidden'}`} style={{ transition: 'opacity 0.3s' }}>
                  {item.label}
                </span>
              </li>
            ))}
          </ul>
          {/* Bottom Menu */}
          <ul className="flex flex-col px-1 space-y-4 pb-5">
            {menubottom.map((item) => (
              <li
                key={item.key}
                className={`p-2 cursor-pointer rounded flex items-start justify-start  hover:bg-primary/30 ${
                  location.pathname === item.path ? 'bg-primary/30 dark:bg-primary/30' : ''
                }`}
              >
                <div className={`${visible ? 'pr-2' : 'pr-0'}`}>{item.icon}</div>
                <span className={`${visible ? 'visible w-full' : 'hidden'}`} style={{ transition: 'opacity 0.3s' }}>
                  {item.label}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default DrawerMenu;
