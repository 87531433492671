import React from 'react';

const DatabaseIcon = ({ size, color }) => (
  <svg width={size || '24'} height={size || '24'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 2.4375C7.05937 2.4375 3.1875 4.65938 3.1875 7.5V16.5C3.1875 19.3406 7.05937 21.5625 12 21.5625C16.9406 21.5625 20.8125 19.3406 20.8125 16.5V7.5C20.8125 4.65938 16.9406 2.4375 12 2.4375ZM12 3.5625C16.1625 3.5625 19.6875 5.3625 19.6875 7.5C19.6875 9.6375 16.1625 11.4375 12 11.4375C7.8375 11.4375 4.3125 9.6375 4.3125 7.5C4.3125 5.3625 7.8375 3.5625 12 3.5625ZM19.6875 16.5C19.6875 18.6375 16.1625 20.4375 12 20.4375C7.8375 20.4375 4.3125 18.6375 4.3125 16.5V14.5125C5.8125 16.05 8.6625 17.0625 12 17.0625C15.3375 17.0625 18.1875 16.05 19.6875 14.5125V16.5ZM19.6875 12C19.6875 14.1375 16.1625 15.9375 12 15.9375C7.8375 15.9375 4.3125 14.1375 4.3125 12V10.0125C5.8125 11.55 8.6625 12.5625 12 12.5625C15.3375 12.5625 18.1875 11.55 19.6875 10.0125V12Z"
      fill={color || '#1C1C1C'}
    />
  </svg>
);

export default DatabaseIcon;
