import React from 'react';

const System = ({ size, color }) => (
  <svg width={size || '23'} height={size || '24'} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.2923 2.25854H3.87565C3.36732 2.25854 2.87981 2.46048 2.52036 2.81992C2.16092 3.17937 1.95898 3.66688 1.95898 4.17521V12.8002H3.28198V3.50024H17.2923V2.25854Z"
      fill={ color || "black"}
    />
    <path
      d="M19.8333 5.00049H6.41667C5.90833 5.00049 5.42082 5.20242 5.06138 5.56187C4.70193 5.92131 4.5 6.40882 4.5 6.91716V16.5005C4.5 17.0088 4.70193 17.4963 5.06138 17.8558C5.42082 18.2152 5.90833 18.4172 6.41667 18.4172H11.7162V19.8834H10.25V21.2922H16V19.8738H14.5433V18.4172H19.8333C20.3417 18.4172 20.8292 18.2152 21.1886 17.8558C21.5481 17.4963 21.75 17.0088 21.75 16.5005V6.91716C21.75 6.40882 21.5481 5.92131 21.1886 5.56187C20.8292 5.20242 20.3417 5.00049 19.8333 5.00049ZM20.5 17.0005H6V6.50049H20.5V17.0005Z"
      fill={ color || "black"}
    />
  </svg>
);

export default System;
