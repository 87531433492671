import { httpService } from 'api/api';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import * as types from '../types';

export const getLiveData = (data) => (dispatch) => {
  dispatch({
    type: types.GET_LIVE_DATA,
    payload: data,
  });
};

export const updateAlertMessage = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_ALERT_MESSAGE,
    payload: data,
  });
};

export const getLiveDataAlert = (data) => (dispatch) => {
  dispatch({
    type: types.GET_LIVE_DATA_ALERT,
    payload: data,
  });
};

export const getFrequentLiveData = (data) => (dispatch) => {
  dispatch({
    type: types.GET_FREQUENT_LIVE_DATA,
    payload: data,
  });
};

export const updateWellProfileTime = (data) => (dispatch) => {
  dispatch({
    type: types.UPDATE_WELL_PROFILE_TIME,
    payload: data,
  });
};

export const getForceRop = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_FORCE_ROP,
      payload: res.data.value,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getForceCalculationsROP(respone, error);
};

export const setStatusLiveApi = (data) => (dispatch) => {
  dispatch({
    type: types.SET_STATUS_API,
    payload: data,
  });
};
export const setConstantsList = (data) => (dispatch) => {
  dispatch({
    type: types.SET_IMPORT_CONSTANTS_LISTS,
    payload: data,
  });
};

export const chartDelayUpdate = (data) => (dispatch) => {
  dispatch({
    type: types.SET_DELAY_CHART,
    payload: data,
  });
};

export const getTrackTraces = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TRACK_TRACES,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getTrackTraces(respone, error);
};

export const getTrackDriller = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TRACK_TRACES_DRILLER,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getTrackDriller(respone, error);
};

export const getTrackTanks = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TRACK_TRACES_TANKS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getTrackTanks(respone, error);
};

export const getDrillingTanks = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_DRILLING_TRACES_TANKS,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getDrillingTanks(respone, error);
};

export const getGaugesDriller = () => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_GAUGES_TRACES_DRILLER,
      payload: res.data,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getGaugesDriller(respone, error);
};

export const getDrillStringLength = (key) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_DRILL_STRING_LENGTH,
      payload: res.data.bit_strings_length,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getRedisKey(respone, error, key);
};

export const getAutoDepthReference = (key) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_AUTO_DEPTH_REFERENCE_WEIGHT,
      payload: res?.data?.auto_depth_reference_weight,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getRedisKey(respone, error, key);
};

export const getTallyMode = (key) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TALLY_MODE,
      payload: res.data.tally_mode,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getRedisKey(respone, error, key);
};

export const getTallyStandStatus = (key) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TALLY_STAND_STATUS,
      payload: res.data.auto_tally_stand_status,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getRedisKey(respone, error, key);
};

export const getTallyStandNumber = (key) => async (dispatch) => {
  const respone = (res) => {
    dispatch({
      type: types.GET_TALLY_STAND_NUMBER,
      payload: res.data.auto_tally_stand_number,
    });
  };

  const error = (err) => {
    ApiErrorHandler(err);
  };

  await httpService.getRedisKey(respone, error, key);
};