import { useEffect, useRef, useState } from 'react';
import { httpService } from 'api/api';
import ApiToastHandle from 'containers/ApiToastHandle';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import {
  getStoredStands,
  getStringTypes,
  getToolsInsertedAll,
  getToolsInsertedWithStand,
  insertedStandsLength,
} from 'store/actions/addTools';
import { getDrillerLogs } from 'store/actions/drillerMenu';
import ApiErrorHandler from 'utils/ApiErrorHandler';
import { getAutoDepth, getSafetyToolsStatus } from 'store/actions/general';
import { Modal } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { getTallyStandNumber, getTallyStandStatus } from 'store/actions/liveData';

const F2_KEY = 113;
const F3_KEY = 114;

const F8_KEY = 119;
const F9_KEY = 120;

const PipeController = () => {
  const dispatch = useDispatch();
  const { toolInsertedAll, stringTypes } = useSelector((state) => state.addTools);
  const { safetyToolsStatus } = useSelector((state) => state.settings);
  const realTimeData = useSelector((state) => state.liveData.data);
  const { auto_tally_stand_number, auto_tally_stand_status } = useSelector((state) => state.liveData);
  const [data, setData] = useState(toolInsertedAll);
  const [openPipeModal, setOpenPipeModal] = useState(false);
  const [modalTxt, setModalTxt] = useState('adding');
  const autoTally = realTimeData?.boolAutoBHA ? JSON.parse(realTimeData?.boolAutoBHA) : false;
  const { autoDepthstatus } = useSelector((state) => state.general);

  const confirmButtonRef = useRef(null);

  useEffect(() => {
    dispatch(getAutoDepth('auto_depth'));
    dispatch(getSafetyToolsStatus());
    dispatch(getStringTypes());
    dispatch(getToolsInsertedAll('store_stand=all'));
    dispatch(getToolsInsertedWithStand('store_stand=stand'));
    dispatch(getDrillerLogs());
    dispatch(getTallyStandNumber('auto_tally_stand_number'));
    dispatch(getTallyStandStatus('auto_tally_stand_status'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setData(toolInsertedAll);
      // Step 1: Find the "Drill pipe" id from stringTypes
      const drillPipeTypeId = _.find(stringTypes, { name: 'Drill pipe' })?.id;
      if (drillPipeTypeId) {
        // Step 2: Filter active tools with matching string_type
        const activeDrillPipes = _.filter(toolInsertedAll, (tool) => tool.active === true && tool?.string_type === drillPipeTypeId);
        // Step 3: Sum any relevant value, for example, the 'length'
        const totalLength = activeDrillPipes?.length;
        // Step 4: Divide the result by auto_tally_stand_number
        const computedResult = Number(totalLength / auto_tally_stand_number).toFixed(2);
        // Set the insertedStandsLength in state
        dispatch(insertedStandsLength(computedResult));
      }
  }, [auto_tally_stand_number, toolInsertedAll]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      const { keyCode } = event;
      const isF2Key = keyCode === F2_KEY;
      const isF3Key = keyCode === F3_KEY;
      const isF8Key = keyCode === F8_KEY;
      const isF9Key = keyCode === F9_KEY;

      if (isF2Key || isF3Key) {
        event.preventDefault();
        event.stopPropagation();

        if (safetyToolsStatus) {
          setModalTxt(isF2Key ? 'adding' : 'removing');
          setOpenPipeModal(true);
        } else if (isF2Key) {
          handleF2Key();
        } else {
          handleF3Key();
        }
      }

      if (isF8Key) {
        // dispatch(getToolsInsertedAll('store_stand=all'));
        updateStandPipe({ stand_number: Number(auto_tally_stand_number), stand_event: true });
      }
      if (isF9Key) {
        // dispatch(getToolsInsertedAll('store_stand=all'));
        updateStandPipe({ stand_number: Number(auto_tally_stand_number), stand_event: false });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [data, autoTally, autoDepthstatus?.auto_depth, safetyToolsStatus]);

  const updateStandPipe = (dataItem) => {
    if (auto_tally_stand_status !== '1') return ApiToastHandle(210, 'stand status is OFF', 'info');
    if (!Number(auto_tally_stand_number)) return ApiToastHandle(210, 'please check stands number in settings', 'info');

    const response = (res) => {
      ApiToastHandle(200, 'action was successful', 'success');
      dispatch(getToolsInsertedWithStand('store_stand=stand'));
      dispatch(getToolsInsertedAll('store_stand=all'));
      dispatch(getStoredStands());
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.updateStandPipe(response, error, dataItem);
    return '';
  };

  const handleF2Key = () => {
    const latestDeactiveItem = _.findLast(data, { active: false });
    if (latestDeactiveItem) {
      updateTools(latestDeactiveItem.id, { active: true, xid: latestDeactiveItem.index });
    } else {
      ApiToastHandle(400, 'All the strings are active!', 'warning');
    }
  };

  const handleF3Key = () => {
    const firstActiveItem = _.find(data, { active: true });
    // if (firstActiveItem?.stand_group_number)
    //   return ApiToastHandle(210, 'Item is in stand. Remove Stand with F9.', 'info');

    if (firstActiveItem) {
      updateTools(firstActiveItem.id, { active: false, xid: firstActiveItem.index });
    } else {
      ApiToastHandle(400, 'All the strings are inactive!', 'warning');
    }
  };

  // eslint-disable-next-line consistent-return
  const updateTools = (id, data, isTally) => {
    if (isTally) {
      return ApiToastHandle(400, `Auto Tally is ON you can't add/remove pipe when it's ON`, 'warning');
    }

    const response = () => {
      ApiToastHandle(200, "pipe's list updated successfully", 'success');
      dispatch(getAutoDepth('auto_depth'));
      dispatch(getToolsInsertedAll('store_stand=all'));
      dispatch(getToolsInsertedWithStand('store_stand=stand'));
      dispatch(getStoredStands());
      setModalTxt('adding');
      setOpenPipeModal(false);
    };

    const error = (err) => {
      ApiErrorHandler(err);
    };

    httpService.updateTools(response, error, id, data);
  };

  const checkAutoTallyAndAutoDepth = () => {
    if (autoTally) {
      ApiToastHandle(410, 'Auto tally is On!', 'warning');
      return false;
    }
    if (Number(autoDepthstatus.auto_depth) === 1) {
      ApiToastHandle(410, 'Auto Depth is On!', 'warning');
      return false;
    }

    return true;
  };

  const handlePipes = () => {
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'adding') {
      handleF2Key(autoTally);
    }
    if (checkAutoTallyAndAutoDepth() && modalTxt === 'removing') {
      handleF3Key(autoTally);
    }
  };

  const handleCancel = () => {
    setOpenPipeModal(false);
    setModalTxt('Adding');
  };

  const ModalTitle = () => (
    <p className="text-lg">
      <WarningOutlined style={{ color: '#F5D503', fontSize: 23, marginRight: 5 }} />
      {`${modalTxt} Pipe.`}
    </p>
  );

  return (
    <Modal
      open={openPipeModal}
      className="capitalize"
      title={<ModalTitle />}
      centered
      maskClosable={false}
      onOk={handlePipes}
      onCancel={handleCancel}
      afterOpenChange={(open) => {
        if (open && confirmButtonRef.current) {
          confirmButtonRef.current.focus();
        }
      }}
      okButtonProps={{
        style: { backgroundColor: '#3C8CA3' },
        ref: confirmButtonRef,
      }}
      okText="Confirm"
    >
      <p className="text-md capitalize">
        Please confirm <span className="text-md text-primary font-bold">{modalTxt}</span> Pipe.
      </p>
    </Modal>
  );
};

export default PipeController;
