import React, { Suspense, useMemo, useState, useEffect } from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fitToScreen } from 'hooks/FitToScreem';
import { Button, Dropdown } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import LazyLoadingIndicator from 'components/shared/LazyLoadingIndicator';
import {
  BackSign,
  CarbonReport,
  DateIcon,
  Export,
  Log,
  Menu,
  MenuSecond,
  PrimeFileExport,
  SettingLight,
} from 'components/icons';
import GetTime from 'hooks/getTime';
import useDarkMode from 'hooks/useDarkMode';
import Cookies from 'js-cookie';
import { currentUser } from 'store/actions/general';
import { getSetting } from 'store/actions/settings';
import { setConstantsList } from 'store/actions/liveData';
import getLogo from 'utils/getLogo';
import UserPopover from './components/UserPopover';
import DrawerMenu from './includes/DrawerMenu';

const socketSettings = new WebSocket(process.env.REACT_APP_API_SOCKET_RELOAD);

const SetupProjectLayout = () => {
  const dispatch = useDispatch();
  const { projects } = useSelector((state) => state.general);
  const settingsData = useSelector((state) => state.settings.settings);
  const { clientLogo } = useSelector((state) => state.settings);

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isDarkMode] = useDarkMode();
  const navigate = useNavigate();
  // eslint-disable-next-line no-undef
  const logo = getLogo(clientLogo, settingsData, isDarkMode);

  useEffect(() => {
    dispatch(currentUser());
    dispatch(getSetting());
    socketSettings.addEventListener('open', () => {
      console.log('connected to SetupProjectLayout ');
    });

    socketSettings.addEventListener('disconnect', () => {
      console.log('disconnected from socketSettings');
      setTimeout(socketSettings?.connect(), 3000);
    });

    socketSettings.addEventListener('message', (event) => {
      if (JSON.parse(event.data).event.includes('SETUP')) {
        dispatch(setConstantsList(event.data));
      }
    });

    socketSettings.addEventListener('error', (error) => {
      console.log('WebSocket error:', error);
    });

    return () => {
      socketSettings.close();
    };
  }, []);

  const projectsLength = useMemo(
    () => Cookies.get('projectsLength') || projects?.length?.toString(),
    [projects.length]
  );

  const items = [
    {
      key: '1',
      icon: <SettingLight color={isDarkMode ? '#fff' : undefined} />,
      label: (
        <Link to="/settings">
          <div className="pl-1">settings</div>
        </Link>
      ),
    },
    {
      key: '5',
      disabled: true,
      icon: <HistoryOutlined className="p-0 m-0 text-xl" color={isDarkMode ? '#fff' : undefined} />,
      label: (
        <Link to="/historical">
          <div className="pl-1">historical</div>
        </Link>
      ),
    },
    {
      key: '2',
      icon: <MenuSecond color={isDarkMode ? '#fff' : undefined} />,
      label: (
        <Link to="/settings">
          <div className="pl-1">driller’s menu</div>
        </Link>
      ),
    },
    {
      key: '3',
      icon: <Log color={isDarkMode ? '#fff' : undefined} />,
      label: (
        <Link to="/logs">
          <div className="pl-1">Logs</div>
        </Link>
      ),
    },
    {
      key: '4',
      icon: <Export color={isDarkMode ? '#FFFFFF' : undefined} />,
      label: <span>Export</span>,
      children: [
        {
          key: '4-1',
          icon: <PrimeFileExport color={isDarkMode ? '#FFFFFF' : undefined} />,
          label: (
            <span className="pl-1">
              <Link to="/export">Export File</Link>
            </span>
          ),
        },
        {
          key: '4-2',
          icon: <CarbonReport color={isDarkMode ? '#FFFFFF' : undefined} />,
          label: (
            <span className="pl-1">
              <Link to="/report-page">Visual Report</Link>
            </span>
          ),
        },
      ],
    },
  ];

  return (
    <div className="bg-gray-100 text-primaryDark font-normal w-screen h-screen max-h-screen overflow-hidden dark:bg-darker dark:text-white">
      <ul
        className=" bg-white py-3 px-4 m-2 rounded-md border border-gray-200 flex items-center justify-between dark:bg-dark dark:border-lightDark"
        style={{ boxShadow: '0px 4px 16px rgba(153, 153, 153, 0.2)' }}
      >
        <div className="flex justify-start items-center gap-x-4">
          <div>
            {logo ? (
              <div className="w-fit max-w-28 min-w-12">
                <img
                  src={`${process.env.REACT_APP_API_MEDIA}${logo}`}
                  className="h-[23px] md:h-[33px] lg:h-[43px] w-full"
                  alt="mainlogo saxon"
                />
              </div>
            ) : (
              <h2 className="text-2xl font-semibold p-0 m-0 mb-2">{settingsData.company_name || 'saxon'}</h2>
            )}
          </div>
        </div>
        <UserPopover isDarkMode={isDarkMode} />
      </ul>
      {/* <div className="bg-white rounded-md border border-gray-200 m-2 " > */}
      <div
        className="bg-white rounded-md border border-gray-200 m-2 dark:bg-dark dark:text-white dark:border-lightDark"
        style={fitToScreen}
      >
        <Suspense fallback={<LazyLoadingIndicator />}>
          <div className="lg:h-[calc(100vh-12.60vh)] flex items-start gap-x-2">
            {settingsData?.initialized && <DrawerMenu />}
            <div className="flex-1 w-full h-full">
              <Outlet />
            </div>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default SetupProjectLayout;
