import * as types from '../types';

const initialState = {
  threads: [],
  tools: [],
  toolInserted: [],
  toolInsertedAll: [],
  toolInsertedWithStand: [],
  casingTools: [],
  insertedCasingTools: [],
  storedStands: [],
  stringTypes: [],
  storedStandsLength: 0,
  insertedStandsLength: 0,
};

export default function addTools(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case types.GET_THREADS:
      return {
        ...state,
        threads: payload,
      };
    case types.GET_TOOLS:
      return {
        ...state,
        tools: payload,
      };
    case types.GET_TOOLS_INSERTED:
      return {
        ...state,
        toolInserted: payload,
      };
    case types.GET_TOOLS_INSERTED_ALL:
      return {
        ...state,
        toolInsertedAll: payload,
      };
    case types.GET_TOOLS_INSERTED_WITH_STAND:
      return {
        ...state,
        toolInsertedWithStand: payload,
      };
    case types.GET_CASING_TOOLS:
      return {
        ...state,
        casingTools: payload,
      };
    case types.GET_STORED_STAND:
      return {
        ...state,
        storedStands: payload,
      };
    case types.GET_INSERTED_CASING_TOOLS:
      return {
        ...state,
        insertedCasingTools: payload,
      };
    case types.GET_STORED_STAND_LENGTH:
      return {
        ...state,
        storedStandsLength: payload,
      };
    case types.GET_INSERTED_STAND_LENGTH:
      return {
        ...state,
        insertedStandsLength: payload,
      };
    case types.GET_STRING_TYPES:
      return {
        ...state,
        stringTypes: payload,
      };
    default:
      return state;
  }
}
